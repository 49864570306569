import React, { useState, useEffect, useReducer } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Configuration from '../functions/Configuration';
import GetUrlHashValue from '../functions/GetUrlHashValue';
import GetCurrentDateTimeString from '../functions/GetCurrentDateTimeString';
import makeDisplay00 from '../functions/makeDisplay00';
import OutsiderNavbar from '../components/OutsiderNavbar';
import DateDropdown from '../components/DateDropdown';
import password_show from '../res/password_show.svg';
import password_hide from '../res/password_hide.svg'

function Register() {
  const { state } = useLocation();

  const [input_state, setInputState] = useState({
    name: "",
    lastname: "",
    english_name: "",
    english_lastname: "",
    gender: "null",
    birthday: "",
    age: 0,
    address: {
      building_no: "",
      building_floor: "",
      residential_no: "",
      residential_local: "",
      residential_alley: "",
      residential_street: "",
      province_id: 0,
      district_id: 0,
      subdistrict_id: 0,
      postal_code: "",
    },
    facebook_id: "",
    line_id: "",
    phone_number: "",
    email: "",
    is_handycap: "null",
    primary_handycap_type: 0,
    secondary_handycap_type: 0,
    password: "",
    where_do_you_find_us: "unknown",
    agree_check: false,
  });

  const [is_loaded, setLoaded] = useState(false)
  const [district_loaded, setDistrictLoaded] = useState(false)
  const [subdistrict_loaded, setSubDistrictLoaded] = useState(false)

  const [selected_birthyear, setBirthYear] = useState(null);
  const [selected_birthmonth, setBirthMonth] = useState(null);
  const [selected_birthdate, setBirthDate] = useState(null);
  const [date_enabled, setDateShouldEnable] = useState(false);

  const [disability_enum, setDisabilityEnum] = useState([]);
  const [major_disability_enum, setMajorDisabilityEnum] = useState([]);
  const [province_enum, setProvinceEnum] = useState([]);
  const [district_enum, setDistrictEnum] = useState([]);
  const [subdistrict_enum, setSubDistrictEnum] = useState([]);

  const [where_do_you_find_us_pjm, setPJM] = useState("");
  const [where_do_you_find_us_others, setOthers] = useState("");

  const [reenable_disabled, setReEnableDisabled] = useState(false);
  const [reenable_district, setReEnableDistrict] = useState(false);
  const [reenable_subdistrict, setReEnableSubDistrict] = useState(false);

  const [is_error, setIsError] = useState(false)
  const [error_msg, setErrorMsg] = useState("")

  const [passwordState, setPasswordState] = useState({  
    passwordType: "password",
    passwordEyeSrc: password_show
  })
  const [passwordConfirmState, setPasswordConfirmState] = useState({  
    passwordType: "password",
    passwordEyeSrc: password_show
  })

  const navigate = useNavigate();

  const setValue = (name, value) => {
    setInputState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const setAddressValue = (name, value) => {
    setInputState(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value
      }
    }));
  }

  const setBoolValue = (name, value) => {
    let into_bool = (value === "true" || value === true)
    setInputState(prevState => ({
      ...prevState,
      [name]: into_bool
    }));

    if (name === "is_handycap" && !into_bool) {
      setReEnableDisabled(true)
      setTimeout(() => {
        setInputState(prevState => ({
          ...prevState,
          handycap_type: []
        }));
        setTimeout(() => {
          setReEnableDisabled(false)
        }, 1);
      }, 1);
    }

    if (name === "is_handycap" && into_bool) {
      setReEnableDisabled(true)
      setTimeout(() => {
        setReEnableDisabled(false)
      }, 1);
    }
  }

  const setNumValue = (name, value) => {
    setInputState(prevState => ({
      ...prevState,
      [name]: parseInt(value)
    }));
  }

  const setNumAddressValue = (name, value) => {
    if (name === "province_id") {
      setReEnableDistrict(true)
      setReEnableSubDistrict(true)
      for (let i in province_enum) {
        if (province_enum[i].id === parseInt(value)) {
          setDistrictEnum(province_enum[i].district)
        }
      }
      setDistrictLoaded(false)
      setSubDistrictLoaded(false)
      setTimeout(() => {
        setInputState(prevState => ({
          ...prevState,
          address: {
            ...prevState.address,
            district_id: 0,
            subdistrict_id: 0,
          }
        }));
        setDistrictLoaded(true)
        setReEnableDistrict(false)
        setReEnableSubDistrict(false)
      }, 1);

    } else if (name === "district_id") {
      setReEnableSubDistrict(true)
      for (let j in district_enum) {
        if (district_enum[j].id === parseInt(value)) {
          setSubDistrictEnum(district_enum[j].subdistrict)
        }
      }
      setSubDistrictLoaded(false)
      setTimeout(() => {
        setInputState(prevState => ({
          ...prevState,
          address: {
            ...prevState.address,
            subdistrict_id: 0,
          }
        }));
        setSubDistrictLoaded(true)
        setReEnableSubDistrict(false)
      }, 1);

    }

    setInputState(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: parseInt(value)
      }
    }));
  }

  const setCheckBoxValueSingle = (value, check) => {
    if (check) {
      setInputState(prevState => ({
        ...prevState,
        where_do_you_find_us: value,
      }));
    } else {
      setInputState(prevState => ({
        ...prevState,
        where_do_you_find_us: "unknown",
      }));
    }
  }

  const setBirthday = (type, value) => {

    if (value === "null") {
      value = null
    }
    if (type === "year") {
      setBirthYear(value)
      setDateShouldEnable(false)
      setBirthDate(null)
      setTimeout(() => {
        setDateShouldEnable(true)
      }, 1);

    } else if (type === "month") {
      setBirthMonth(value)
      setDateShouldEnable(false)
      setBirthDate(null)
      setTimeout(() => {
        setDateShouldEnable(true)
      }, 1);

    } else if (type === "date") {
      setBirthDate(value)
    }
  }

  const getBirthdayString = () => {
    if (!selected_birthyear || !selected_birthmonth || !selected_birthdate) {
      return "One of birthday selections is missing"
    }
    return selected_birthyear + "-" + makeDisplay00(selected_birthmonth) + "-" + makeDisplay00(selected_birthdate)
  }

  const getAge = () => { //ref : https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd
    if (!selected_birthyear || !selected_birthmonth || !selected_birthdate) {
      return "One of birthday selections is missing"
    }
    let today = new Date(GetCurrentDateTimeString());
    let birthDate = new Date(getBirthdayString());
    let age = today.getFullYear() - birthDate.getFullYear();
    let month_age = today.getMonth() - birthDate.getMonth();
    if (month_age < 0 || (month_age === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age
  }

  const getWhereDidFound = (init_value) => {
    if (input_state.where_do_you_find_us === "พมจ") {
      if (where_do_you_find_us_pjm === "") {
        return null
      }
      return init_value + " : " + where_do_you_find_us_pjm
    } else if (input_state.where_do_you_find_us === "others") {
      if (where_do_you_find_us_others === "") {
        return null
      }
      return init_value + " : " + where_do_you_find_us_others
    } else {
      return init_value
    }
  }

  const registerCompletion = () => {

    const url = Configuration("user_host") + "/api/v1/login/register";
    let body = input_state

    if (body["agree_check"] !== true) {
      setErrorMsg("Please Agree to Privacy Policy")
      setIsError(true)
      return
    }

    if (body["password"] !== body["password_confirm"]) {
      setErrorMsg("Password Confirm Error")
      setIsError(true)
      return
    }

    if (!selected_birthyear || !selected_birthmonth || !selected_birthdate) {
      setErrorMsg("One of birthday selections is missing")
      setIsError(true)
      return
    }

    body["birthday"] = getBirthdayString()
    let age = getAge()
    if (age <= 0) {
      setErrorMsg("Please input valid birthdate")
      setIsError(true)
      return
    }
    body["age"] = age
    let where_to_find_value = getWhereDidFound(body["where_do_you_find_us"])
    if (where_to_find_value === null) {
      setErrorMsg("Please fill additional field in the checkbox")
      setIsError(true)
      return
    }
    if (input_state.is_handycap) {
      if (!input_state.primary_handycap_type) {
        setErrorMsg("Please put atleast one primary handycap type")
        setIsError(true)
        return
      }
    }
    body["where_do_you_find_us"] = where_to_find_value

    const postData = async () => {
      try {
        await axios.post(url, body);

        setIsError(false)

        navigate('/register_success', { state: { truth: true } })

      } catch (error) {
        if (error.response.status < 500) {
          if (error.response.data.Data?.Message) {
            setErrorMsg(error.response.data.Data.Message)
          } else {
            setErrorMsg("Registeration Input Error")
          }
        } else {
          setErrorMsg("Internal Server Error")
        }
        setIsError(true)
      }
    };

    postData()
  }

  const openPrivacyPolicy = () => {
    window.open('/policy');
  }
  
  const onClickPasswordEye = () => {
    if(passwordState.passwordType == "password"){
      setPasswordState({
        passwordEyeSrc: password_hide,
        passwordType: "text",
      })
    }else{
      setPasswordState({
        passwordEyeSrc: password_show,
        passwordType: "password",
      })
    }
  }
  const onClickPasswordConfirmEye = () => {
    if(passwordConfirmState.passwordType == "password"){
      setPasswordConfirmState({
        passwordEyeSrc: password_hide,
        passwordType: "text",
      })
    }else{
      setPasswordConfirmState({
        passwordEyeSrc: password_show,
        passwordType: "password",
      })
    }
  }

  useEffect(
    () => {
      document.body.style.background = "#503e9d"
      document.body.style.padding = "0 0 0 0";

      let is_debug_mode = false //TURN FALSE WHEN FINISHED DEBUGGING OR TESTING!!!!!!**********

      if (!is_debug_mode) {
        if (!state) {
          navigate('/register_invalid_link')
          return
        }
        let user_email = state.email
        if (user_email) {
          setInputState(prevState => ({
            ...prevState,
            email: user_email
          }));
        } else {
          navigate('/register_invalid_link')
          return
        }
      } else {
        setInputState(prevState => ({
          ...prevState,
          email: "DEBUG MODE"
        }));
      }

      const url = Configuration("user_host") + "/api/v1/enum/disability-info";

      const url2 = Configuration("user_host") + "/api/v1/enum/major-disability-info";

      const url3 = Configuration("user_host") + "/api/v1/enum/province-info";

      const fetchDataAll = async () => {
        try {
          const data_arr = await Promise.all([
            axios.get(url),
            axios.get(url2),
            axios.get(url3)
          ]);

          for (let i in data_arr) {
            let index = parseInt(i)
            let response = data_arr[index].data

            if (index === 0) {
              setDisabilityEnum(response.data)
            } else if (index === 1) {
              setMajorDisabilityEnum(response.data)
            } else if (index === 2) {
              setProvinceEnum(response.data)
              setLoaded(true)
            }

          }

        } catch (error) {
          console.log("error", error);
        }
      };

      fetchDataAll()
    },
    []
  )

  return (
    <div>

      <div>

        <OutsiderNavbar />

        {is_loaded ? <div className="regis_box">
          <h3 className="regis_header">สร้างบัญชีผู้ใช้งาน</h3>

          <div className="regis_subbox">
            <p className="regis_subheader">ข้อมูลส่วนตัว</p>
            <div className="outsider_txt_box_double">
              <label className="outsider_txt_box_in_double_1 label_icon label_icon_regis label_icon_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" name="name" placeholder="ชื่อ" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
              <label className="outsider_txt_box_in_double_2 label_icon label_icon_regis label_icon_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" name="lastname" placeholder="นามสกุล" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
            </div>

            <div className="outsider_txt_box_double">
              <label className="outsider_txt_box_in_double_1 label_icon label_icon_regis label_icon_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" name="english_name" placeholder="ชื่อ (ภาษาอังกฤษ)" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
              <label className="outsider_txt_box_in_double_2 label_icon label_icon_regis label_icon_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" name="english_lastname" placeholder="นามสกุล (ภาษาอังกฤษ)" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
            </div>

            <div>
              <label className="outsider_txt_box_single label_icon label_icon_regis label_icon_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" maxLength="13" name="personal_id" placeholder="เลขบัตรประจำตัวประชาชน (เลข 13 หลัก)" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
            </div>

            <div className="outsider_txt_box_quadruple">
              <div className="hidden_label">เพศ</div>
              <select className={"outsider_txt_box outsider_txt_box_in_quadruple_1 " + (input_state.gender === "null" ? "null_dropdown" : "")} name="gender" defaultValue="null" onChange={e => setValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>เพศ</option>
                <optgroup label="เพศ">
                  <option value="ชาย">เพศ : ชาย</option>
                  <option value="หญิง">เพศ : หญิง</option>
                </optgroup>
              </select>
              <div className="hidden_label">ปีเกิด</div>
              <DateDropdown classNames={"outsider_txt_box outsider_txt_box_in_quadruple_2 " + (selected_birthyear === "null" || selected_birthyear === null ? "null_dropdown" : "")} initDisplay={"ปีเกิด"} valueName={"birthday_year"} valueType={"year"} parentCallback={setBirthday} />
              <div className="hidden_label">เดือนเกิด</div>
              <DateDropdown classNames={"outsider_txt_box outsider_txt_box_in_quadruple_3 " + (selected_birthmonth === "null" || selected_birthmonth === null ? "null_dropdown" : "")} initDisplay={"เดือนเกิด"} valueName={"birthday_month"} valueType={"month"} parentCallback={setBirthday} />
              <div className="hidden_label">วันที่เกิด</div>
              {selected_birthyear && selected_birthmonth && date_enabled ?
                <DateDropdown classNames={"outsider_txt_box outsider_txt_box_in_quadruple_4 " + (selected_birthdate === "null" || selected_birthdate === null ? "null_dropdown" : "")} initDisplay={"วันที่เกิด"} valueName={"birthday_date"} valueType={"date"} preSelectYear={selected_birthyear} preSelectMonth={selected_birthmonth} parentCallback={setBirthday} />
                : <select className="outsider_txt_box outsider_txt_box_in_quadruple_4 outsider_txt_box_disabled" disabled>
                  <option value="null">วันที่เกิด</option>
                </select>}
            </div>

            <div className="outsider_txt_box_triple">
              <div className="hidden_label">คุณเป็นคนพิการหรือไม่?</div>
              <select className={"outsider_txt_box outsider_txt_box_in_triple_1 " + (input_state.is_handycap === "null" ? "null_dropdown" : "")} name="is_handycap" defaultValue="null" onChange={e => setBoolValue(e.target.name, e.target.value)}>
                <option disabled value="null" hidden>คุณเป็นคนพิการหรือไม่?</option>
                <optgroup label="คุณเป็นคนพิการหรือไม่?">
                  <option value="true">เป็นคนพิการ</option>
                  <option value="false">ไม่ได้เป็นคนพิการ</option>
                </optgroup>
              </select>
              <div className="hidden_label">ประเภทความพิการ</div>
              {input_state.is_handycap === true ? <select className={"outsider_txt_box outsider_txt_box_in_triple_2 " + (input_state.primary_handycap_type === 0 ? "null_dropdown" : "")} defaultValue={0} name="primary_handycap_type" onChange={e => setNumValue(e.target.name, e.target.value)}>
                <option disabled={!reenable_disabled} value={0} hidden>ประเภทความพิการ</option>
                <optgroup label="ประเภทความพิการ">
                  {major_disability_enum ? (major_disability_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>({index + 1}) {item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select> : <select className="outsider_txt_box outsider_txt_box_in_triple_2 outsider_txt_box_disabled" defaultValue={0} disabled>
                <option value={0}>ประเภทความพิการ</option>
              </select>}
              <div className="hidden_label">ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)</div>
              {input_state.is_handycap === true ? <select className={"outsider_txt_box outsider_txt_box_in_triple_3 " + (input_state.secondary_handycap_type === 0 ? "null_dropdown" : "")} defaultValue={0} name="secondary_handycap_type" onChange={e => setNumValue(e.target.name, e.target.value)}>
                <option disabled={!reenable_disabled} value={0} hidden>ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)</option>
                <optgroup label="ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)">
                  {major_disability_enum ? (major_disability_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>({index + 1}) {item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select> : <select className="outsider_txt_box outsider_txt_box_in_triple_3 outsider_txt_box_disabled" defaultValue={0} disabled>
                <option value={0}>ประเภทความพิการที่ 2 (กรณีพิการซ้ำซ้อน)</option>
              </select>}
            </div>

            <div className="outsider_txt_box_double">
              <label className="outsider_txt_box_in_double_1 label_icon label_icon_regis label_icon_loaded_email">
                <input className="outsider_txt_box outsider_txt_box_with_icon outsider_txt_box_email" type="email" name="email" value={input_state.email} disabled />
              </label>
              <label className="outsider_txt_box_in_double_2 label_icon label_icon_regis label_icon_phone">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type="text" name="phone_number" placeholder="เบอร์โทรศัพท์" onChange={e => setValue(e.target.name, e.target.value)} />
              </label>
            </div>

            <div className="outsider_txt_box_double">
              <input className="outsider_txt_box outsider_txt_box_in_double_1" type="text" name="facebook_id" placeholder="Facebook (กรณีไม่มีให้เว้นว่าง)" onChange={e => setValue(e.target.name, e.target.value)} />
              <input className="outsider_txt_box outsider_txt_box_in_double_2" type="text" name="line_id" placeholder="Line ID (กรณีไม่มีให้เว้นว่าง)" onChange={e => setValue(e.target.name, e.target.value)} />
            </div>

            <br />
          </div>

          <div className="regis_subbox">
            <p className="regis_subheader">ที่อยู่ปัจจุบัน</p>
            <div className="outsider_txt_box_triple">
              <input className="outsider_txt_box outsider_txt_box_in_triple_1" type="text" name="building_no" placeholder="ชื่ออาคาร: (กรณีไม่มีให้เว้นว่าง)" onChange={e => setAddressValue(e.target.name, e.target.value)} />
              <input className="outsider_txt_box outsider_txt_box_in_triple_2" type="text" name="building_floor" placeholder="ชั้นที่: (กรณีไม่มีให้เว้นว่าง)" onChange={e => setAddressValue(e.target.name, e.target.value)} />
              <input className="outsider_txt_box outsider_txt_box_in_triple_3" type="text" name="residential_no" placeholder="บ้านเลขที่" onChange={e => setAddressValue(e.target.name, e.target.value)} />
            </div>

            <div className="outsider_txt_box_triple">
              <input className="outsider_txt_box outsider_txt_box_in_triple_1" type="text" name="residential_local" placeholder="หมู่ที่: (กรณีไม่มีให้เว้นว่าง)" onChange={e => setAddressValue(e.target.name, e.target.value)} />
              <input className="outsider_txt_box outsider_txt_box_in_triple_2" type="text" name="residential_alley" placeholder="ตรอก/ซอย: (กรณีไม่มีให้เว้นว่าง) " onChange={e => setAddressValue(e.target.name, e.target.value)} />
              <input className="outsider_txt_box outsider_txt_box_in_triple_3" type="text" name="residential_street" placeholder="ถนน (กรณีไม่มีให้เว้นว่าง)" onChange={e => setAddressValue(e.target.name, e.target.value)} />
            </div>

            <div className="outsider_txt_box_quadruple">
              <div className="hidden_label">จังหวัด</div>
              <select className={"outsider_txt_box outsider_txt_box_in_quadruple_1 " + (input_state.address.province_id === 0 ? "null_dropdown" : "")} name="province_id" defaultValue={0} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                <option disabled value={0} hidden>จังหวัด</option>
                <optgroup label="จังหวัด">
                  {province_enum ? (province_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select>
              {input_state.address.province_id > 0 && district_loaded ? <select className={"outsider_txt_box outsider_txt_box_in_quadruple_2 " + (input_state.address.district_id === 0 ? "null_dropdown" : "")} name="district_id" defaultValue={0} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                <option disabled={!reenable_district} value={0} hidden>เขต/อำเภอ</option>
                <optgroup label="เขต/อำเภอ">
                  {district_enum ? (district_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select> : <select className="outsider_txt_box outsider_txt_box_in_quadruple_2 outsider_txt_box_disabled" defaultValue={0} disabled>
                <option value={0}>เขต/อำเภอ</option>
              </select>}
              {input_state.address.district_id > 0 && subdistrict_loaded ? <select className={"outsider_txt_box outsider_txt_box_in_quadruple_3 " + (input_state.address.subdistrict_id === 0 ? "null_dropdown" : "")} name="subdistrict_id" defaultValue={0} onChange={e => setNumAddressValue(e.target.name, e.target.value)}>
                <option disabled={!reenable_subdistrict} value={0} hidden>แขวง/ตำบล</option>
                <optgroup label="แขวง/ตำบล">
                  {subdistrict_enum ? (subdistrict_enum.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>{item.name}</option>
                    )
                  })
                  ) : ""}
                </optgroup>
              </select> : <select className="outsider_txt_box outsider_txt_box_in_quadruple_3 outsider_txt_box_disabled" defaultValue={0} disabled>
                <option value={0}>แขวง/ตำบล</option>
              </select>}
              <input className="outsider_txt_box outsider_txt_box_in_quadruple_4" type="text" name="postal_code" placeholder="รหัสไปรษณีย์" autoComplete='off' onChange={e => setAddressValue(e.target.name, e.target.value)} />
            </div>

            <br />
          </div>

          <div className="regis_subbox">
            <p className="regis_subheader">รหัสผ่าน</p>
            <div className="outsider_txt_box_double">
              <label className="outsider_txt_box_in_double_1 label_icon label_icon_regis label_icon_password">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type={passwordState.passwordType} name="password" autoComplete='off' placeholder="Password" onChange={e => setValue(e.target.name, e.target.value)} />
                <img className='password_eye' alt="show password" src={passwordState.passwordEyeSrc} onClick={onClickPasswordEye}/>
              </label>
              <label className="outsider_txt_box_in_double_2 label_icon label_icon_regis label_icon_password">
                <input className="outsider_txt_box outsider_txt_box_with_icon" type={passwordConfirmState.passwordType} name="password_confirm" autoComplete='off' placeholder="Password Confirm" onChange={e => setValue(e.target.name, e.target.value)} />
                <img className='password_eye' alt="show password" src={passwordConfirmState.passwordEyeSrc} onClick={onClickPasswordConfirmEye}/>
              </label>
            </div>
          </div>

          <div className="regis_subbox regis_subbox_end">
            <p className="obj_subheader_checkbox">คุณรู้จัก Vulcan Academy ได้อย่างไร?</p>
            <label>
              <input className="obj_checkbox" type="checkbox" name="social_media_check" value="social_media" checked={input_state.where_do_you_find_us === "social_media"} onChange={e => setCheckBoxValueSingle(e.target.value, e.target.checked)} />
              <span className="obj_checkbox_label">รู้จักผ่านช่องทางโซเชียลมีเดีย (Facebook, Twitter หรือ Instagram)</span>
            </label><br />
            <label>
              <input className="obj_checkbox" type="checkbox" name="recom_check" value="recommendation" checked={input_state.where_do_you_find_us === "recommendation"} onChange={e => setCheckBoxValueSingle(e.target.value, e.target.checked)} />
              <span className="obj_checkbox_label">ครอบครัวหรือบุคคลรอบข้างแนะนำให้รู้จัก</span>
            </label><br />
            <label>
              <input className="obj_checkbox" type="checkbox" name="news_check" value="news_announcement" checked={input_state.where_do_you_find_us === "news_announcement"} onChange={e => setCheckBoxValueSingle(e.target.value, e.target.checked)} />
              <span className="obj_checkbox_label">รู้จักผ่านช่องทางข่าวประชาสัมพันธ์ของหนังสือพิมพ์ / นิตยสาร</span>
            </label><br />
            <label>
              <input className="obj_checkbox" type="checkbox" name="offi_check" value="พมจ" checked={input_state.where_do_you_find_us === "พมจ"} onChange={e => setCheckBoxValueSingle(e.target.value, e.target.checked)} />
              <span className="obj_checkbox_label">รู้จักผ่านสำนักงานพัฒนาสังคมและความมั่นคงของมนุษย์ประจำจังหวัด (พมจ.)</span>
            </label><br />
            {input_state.where_do_you_find_us === "พมจ" ? <input className="outsider_txt_box outsider_txt_box_checkbox_extra" type="text" name="offi_check_value" placeholder="โปรดระบุจังหวัด" onChange={e => setPJM(e.target.value)} /> :
              <input className="outsider_txt_box outsider_txt_box_checkbox_extra" type="text" name="offi_check_value" placeholder="โปรดระบุจังหวัด" disabled />}<br />
            <label>
              <input className="obj_checkbox" type="checkbox" name="other_check" value="others" checked={input_state.where_do_you_find_us === "others"} onChange={e => setCheckBoxValueSingle(e.target.value, e.target.checked)} />
              <span className="obj_checkbox_label">อื่นๆ</span>
            </label><br />
            {input_state.where_do_you_find_us === "others" ? <input className="outsider_txt_box outsider_txt_box_checkbox_extra" type="text" name="other_check_value" placeholder="โปรดระบุ" onChange={e => setOthers(e.target.value)} /> :
              <input className="outsider_txt_box outsider_txt_box_checkbox_extra" type="text" name="other_check_value" placeholder="โปรดระบุ" disabled />}<br />
          </div>
          <br />

        </div> : ""}

        <div className="regis_subbox_outside regis_subbox_agree_term">
          <label>
            <input className="obj_checkbox" type="checkbox" name="agree_check" onChange={e => setBoolValue(e.target.name, e.target.checked)} />
            <span className="agree_term_checkbox_label">ข้าพเจ้าได้อ่านและยอมรับ <button className="regis_link" onClick={openPrivacyPolicy}>ข้อกำหนดและเงื่อนไข</button> นโยบายการคุ้มครองข้อมูลส่วนบุคคล สำหรับผู้สมัครบัญชีใช้งาน Vulcan Academy แล้ว</span><br />
          </label>
        </div>

        {is_error ? <h6 className="regis_subbox_outside regis_error">{error_msg}</h6> : ""}

        <div className="regis_subbox_outside outsider_btns">
          <input className="outsider_btn_base outsider_btn_regis outsider_btn_regis_final" type="submit" value="สร้างบัญชี" onClick={registerCompletion} />
          <br />
        </div>

      </div>

    </div>
  );
}

export default Register;
